.social-media-icons {
    display: flex;
    width: auto;
    list-style: none;
    justify-content: center;
  }
  .footer-message{
    font-weight: 400;
    opacity: 0.6;
  }
  .social-media-icons a {
    color: #fff;
    text-decoration: none;
    font-size: 16px;
    margin:0 8px;
    opacity: 0.4;
    transition: 120ms;
  }
  .social-media-icons a:hover {
    opacity: 1;
  }