.rdt_Table, .rdt_TableRow, .rdt_TableCol,.rdt_TableHeadRow{
    background-color: transparent !important;
    color:#fff !important;
}
.rdt_TableCol{
@apply py-2 !important;
}
.rdt_TableHeadRow{
    min-height: 45px;
}
.rdt_TableRow ,.rdt_TableHeadRow{
    font-size: 19px;
@apply border-b border-identityGray-300 !important;
}