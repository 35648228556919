.App {
  height: calc(100% - 300px);
}
.container-limited{
  max-height: calc(100% - 200px);
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

::-webkit-scrollbar{
  width:8px;
  @apply bg-identityGray-200;
  border-radius: 4px;
}
::-webkit-scrollbar-thumb{
  width:8px;
  border-radius: 4px;
  @apply bg-identityGray-300;
}



.flexible-modal {
  position: absolute;
  z-index: 1;
  border: 1px solid #ccc;
  background: white;
}
.flexible-modal-mask {
  position: fixed;
  height: 100%;
  background: transparent !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.flexible-modal-resizer {
  position: absolute;
  right: 0;
  bottom: 0;
  cursor: se-resize;
  margin: 5px;
  border-bottom: solid 2px #333;
  border-right: solid 2px #333;
}
.flexible-modal-drag-area {
  background: rgba(0, 0, 0, 1) !important;
  height: 25px !important;
  position: absolute;
  right: 0;
  top: 0;
  cursor: move;
}
.chat-comp {
  width: calc(100%);
  height: calc(100% - 24px);
  margin-top: 25px;
}
.my-modal-custom-class h3 {
  width: 40px;
  left: 50%;
  margin-left: -20px;
  position: absolute;
  z-index: 99;
}
.my-modal-custom-class {
  z-index: 999 !important;
}
@media only screen and (max-width: 768px) {
  .my-modal-custom-class {
    width: 100vw !important;
    height: 100vh !important;
    top: 0 !important;
    left: 0 !important;
    position: fixed !important;
  }
  .flexible-modal-drag-area {
    width: 100% !important;
  }
}

.scroll-creator {
  background: linear-gradient(to bottom, #bdeafc, #fcbdc9);
}

.panel {
  display: inline-block;
  width: 288px;
  box-shadow: 0 0 1px #ddd;
  border-radius: 8px;
}

.panel-header {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  background-color: #333;
  text-align: left;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.panel-content,
.outer-container {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.panel-content-native {
  max-height: 525px;
  overflow: auto;
}

.panel-content-custom {
  max-height: 525px;
}

.content-fill {
  background: #d6eff5;
  line-height: 20px;
  font-size: 12px;
  text-align: left;
  padding: 10px 20px;
}

.crazy-scroll .rcs-custom-scrollbar {
  width: 45px;
}

.crazy-scroll .rcs-inner-handle {
  background-color: inherit;
  background-image: url("http://rommguy.github.io/react-custom-scroll/example/giraffe-icon.png");
  background-repeat: no-repeat no-repeat;
  background-size: cover;
}

.flex-scroll {
  display: flex;
  flex-direction: column;
  height: 400px;
}

.dynamic-content {
  padding: 30px 15px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: white;
}
.example-dynamic-wrapper .panel-content-custom {
  max-height: 250px;
}
.example-dynamic-wrapper .dynamic-content-button {
  display: block;
  border: 10px;
  font-family: Roboto, sans-serif;
  cursor: pointer;
  padding: 0px;
  outline: none;
  height: 36px;
  line-height: 36px;
  border-radius: 2px;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  background-color: rgb(0, 188, 212);
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  text-transform: uppercase;
  margin: 10px auto;
  min-width: 200px;
  user-select: none;
  padding-left: 16px;
  padding-right: 16px;
  color: rgb(255, 255, 255);
}
.example-dynamic-wrapper:hover {
  background-color: rgba(0, 188, 212, 0.6);
}
.rcs-custom-scroll {
  min-height: 0;
  min-width: 0;
  height:100%;
}
.rcs-custom-scroll .rcs-outer-container {
  overflow: hidden;
}
.rcs-custom-scroll .rcs-outer-container .rcs-positioning {
  position: relative;
}
.rcs-custom-scroll .rcs-outer-container:hover .rcs-custom-scrollbar {
  opacity: 1;
  transition-duration: 0.2s;
}
.rcs-custom-scroll .rcs-inner-container {
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.rcs-custom-scroll .rcs-inner-container:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  height: 0;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1  .2) 0%, rgba(0, 0, 0, 0.05) 60%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
  transition: height 0.1s ease-in;
  will-change: height;
}
.rcs-custom-scroll .rcs-inner-container.rcs-content-scrolled:after {
  height: 5px;
  transition: height 0.15s ease-out;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-inner-container {
  user-select: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar {
  position: absolute;
  height: 100%;
  width: 6px;
  right: 3px;
  opacity: 0;
  z-index: 1;
  transition: opacity 0.4s ease-out;
  padding: 6px 0;
  box-sizing: border-box;
  will-change: opacity;
  pointer-events: none;
}
.rcs-custom-scroll .rcs-custom-scrollbar.rcs-custom-scrollbar-rtl {
  right: auto;
  left: 3px;
}
.rcs-custom-scroll.rcs-scroll-handle-dragged .rcs-custom-scrollbar {
  opacity: 1;
}
.rcs-custom-scroll .rcs-custom-scroll-handle {
  position: absolute;
  width: 100%;
  top: 0;
}
.rcs-custom-scroll .rcs-inner-handle {
  height: calc(100% - 12px);
  margin-top: 6px;
  @apply bg-identityGray-300;
  border-radius: 3px;
}