.videoTag {
  position: fixed;
  left: -8%;
  bottom: 0;
  top: 85px;
  min-width: 104%;
  min-height: 100vh;
  transform: translateX(calc((100% - 100vw) / 2));
  opacity: 0.8;
  transition-property: opacity;
  transition-duration: 0.8s;
}

.video-flex {
  position: fixed;
  left: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
}

.home .mobile-page-title {
  display: none;
}
.home .player-wrapper {
  z-index: 99;
  background-color: transparent;
  border:0px;
}
.home .player-footer {
  background-color: transparent;

}
.home .faded-x:after{
  display: none;
}
.home .below-root{
  background-color: #000;
}
.video_contain {
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
}

video {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  min-height: 50%;
  min-width: 50%;
}
.hidden-vid{
  opacity: 0;
}


@-webkit-keyframes fadeInFromNone {
  0% {
    opacity: 0;
}

50% {
    opacity: 1;
}

100% {
    opacity: 0;
}
}

@-moz-keyframes fadeInFromNone {
  0% {
    opacity: 0;
}

50% {
    opacity: 1;
}

100% {
    opacity: 0;
}
}

@-o-keyframes fadeInFromNone {
  0% {
    opacity: 0;
}

50% {
    opacity: 1;
}

100% {
    opacity: 0;
}
}

@keyframes fadeInFromNone {
  0% {
      opacity: 0;
  }

  50% {
      opacity: 1;
  }

  100% {
      opacity: 0;
  }
}
.flash{ 
  position:fixed; 
  top:0;
  left:0;
  width:100%;
  height:100vh;
  background-color:rgb(255, 255, 255, 0.6);
  opacity: 0;
}
.animate-flash{
  -webkit-animation: fadeInFromNone 0.5s ease-in-out;
    -moz-animation: fadeInFromNone  0.5s ease-in-out;
    -o-animation: fadeInFromNone 0.5s ease-in-out;
    animation: fadeInFromNone 0.5s ease-in-out;
}


.noise {
  position: fixed;
  top: -500px;
  right: -500px;
  bottom: -500px;
  left: -500px;
  background: transparent url(https://www.dropbox.com/s/h7ab1c82ctzy83n/noise.png?raw=1) 0 0;
  background-size: 320px 320px;
	opacity: 0;
  transition-property: opacity;
  transition-duration: 0.5s;
  z-index: 9;
}
.noise.animated{
	animation: noise 1s steps(8,end) infinite both;
  opacity: 0.20;
}

@keyframes noise {
  0% {
    transform: translateX(0px,0px); }
  10% {
    transform: translate(-100px, 100px);
  }
  20% {
    transform: translate(150px, -100px);
  }
  30% {
    transform: translate(-100px,100px);
  }
  40% {
    transform: translate(100px, -150px);
  }
  50% {
    transform: translate(-100px, 200px);
  }
  60% {
    transform: translate(-200px, -100px);
  }
  70% {
    transform: translateY(50px, 100px);
  }
  80% {
    transform: translate(100px, -150px);
  }
  90% {
    transform: translate(0px, 200px);
  }
  100% {
    transform: translate(-100px, 100px);
  }
}

@media only screen and (max-width: 1500px) {
  .videoTag {
    position: fixed;
    bottom: 0;
    min-width: auto;
    max-width: none;
    min-height: 100vh;
    height: 100vh;
    opacity: 0.8;
    max-width: none;
    left: 50%;
    top: 0px;
    transform: translate(-52%, 0);
  }
  .hidden-vid{
    opacity: 0;
  }
  
}