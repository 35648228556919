.backdrop {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .modal {
    background: white;
    border-radius: 5px;
    padding: 20px;
    max-width: 500px;
    min-width: 300px;
  }
  
  .modal button {
    margin-top: 15px;
    float: right;
  }