@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Poppins:ital,wght@0,200;0,300;0,400;0,500;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@font-face {
  font-family: 'Coolvetica';
  src: local('Coolvetica'), url('./fonts/coolvetica/Coolvetica.ttf.woff') format('woff'),
    url('./fonts/coolvetica/Coolvetica.ttf.svg#Coolvetica') format('svg'),
    url('./fonts/coolvetica/Coolvetica.ttf.eot'),
    url('./fonts/coolvetica/Coolvetica.ttf.eot?#iefix') format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Tajwal';
  src: local('Tajwal'), url('./fonts/tajawal/Tajawal-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Karagoz';
  src: local('Karagoz'), url('./fonts/karagoz/TSKaragoz-Display.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Markazi';
  src: local('Markazi'), url('./fonts/markazi/MarkaziText-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Markazi';
  src: local('Markazi'), url('./fonts/markazi/MarkaziText-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Markazi';
  src: local('Markazi'), url('./fonts/markazi/MarkaziText-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Markazi';
  src: local('Markazi'), url('./fonts/markazi/MarkaziText-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}


html {
  height: 100vh;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

h1,
h2 {
  font-family: 'Coolvetica', sans-serif;
}

[dir="rtl"] h1,
[dir="rtl"] h2 {
  font-family: 'Karagoz', sans-serif;

}

h3,
h4,
h5,
h6 {
  font-family: 'Poppins', sans-serif;
}

.font-ascii {
  font-family: serif, sans-serif;
  font-size: 30px;
  margin-left: 0px;
  letter-spacing: -2px;
  margin-bottom: -2px;
  line-height: 0;
  margin-top: -3px;
}

.font-general,
[data-lang="en"] {
  font-family: 'Lato', sans-serif;
}

[lang="ar"] .font-general,
[lang="ar"] {
  font-family: 'Markazi', sans-serif;
  font-size: 18px;

}

[data-lang="ar"] {
  font-family: 'Markazi', sans-serif;
  font-size: 16px;

}

.font-titles {
  font-family: 'Coolvetica', sans-serif;
}

[dir="rtl"] .font-titles {
  font-family: 'karagoz', sans-serif;
}

.font-sub {
  font-family: 'Poppins', sans-serif;
}

[dir="rtl"] .font-sub {
  font-family: 'Tajwal', sans-serif;
}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.marquee {
  height: 25px;
  width: auto;

  overflow: hidden;
  position: relative;
}

.marquee div {
  display: flex;
  flex-wrap: nowrap;
  width: auto;
  min-width: 100%;
  height: 30px;
  gap: 25px;
  position: absolute;

}

.marquee.animated div {
  animation: marquee calc(0.05s * var(--r)) linear infinite;
  animation-delay: 2s;
  transition-delay: 2s;
}

[dir="rtl"] .marquee.animated div {
  animation: marquee-rtl calc(0.05s * var(--r)) linear infinite;
}

.marquee span {
  float: left;
  width: auto;
  min-width: 280px;
}

@keyframes marquee {
  0% {
    left: 0;
  }

  100% {
    left: var(--w)
  }
}

@keyframes marquee-rtl {
  0% {
    right: 0;
  }

  100% {
    right: var(--w)
  }
}




.faded-x:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to right,
      rgba(20, 20, 20, 0.2),
      rgba(20, 20, 20, 0) 50%,
      rgba(20, 20, 20, 1) 90%,
      rgba(20, 20, 20, 1) 100%);
  width: 100%;
  height: 4em;
}

.faded-y:after {
  content: "";
  position: absolute;
  z-index: 1;
  bottom: -1px;
  left: 0;
  pointer-events: none;
  background-image: linear-gradient(to bottom,
      rgba(20, 20, 20, 0.0),
      rgba(20, 20, 20, 0) 80%,
      rgba(20, 20, 20, 0.5) 90%,
      rgba(20, 20, 20, 1) 100%);
  width: 100%;
  height: 100%;
}

[dir="rtl"] .faded-x:after {
  background-image: linear-gradient(to left,
      rgba(20, 20, 20, 0.2),
      rgba(20, 20, 20, 0) 50%,
      rgba(20, 20, 20, 1) 90%);
}

.gradiant-colors {
  background: linear-gradient(135deg, #EE4647 13.52%, #F68D3C 37.23%, #F69534 39.51%, #F9B41B 48.63%, #FAC70C 56.38%, #FBCE07 61.86%, #EDCC13 64.14%, #CBC732 68.24%, #93C066 73.89%, #47B6AC 80.64%, #00ADEE 86.48%);
}

.gradiant-colors.animated {
  background-size: 200% 200%;
  animation: AnimationName 4s ease-in-out infinite;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 10%
  }

  50% {
    background-position: 100% 91%
  }

  100% {
    background-position: 0% 10%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 10%
  }

  50% {
    background-position: 100% 91%
  }

  100% {
    background-position: 0% 10%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 10%
  }

  50% {
    background-position: 100% 91%
  }

  100% {
    background-position: 0% 10%
  }
}

.custom-container {
  width: 100%;
  max-width: 1124px;
  margin: 0 auto;
}

.react-calendar.mini-calendar {
  height: 260px;
  width: 260px;
  display: flex;
  flex-direction: column-reverse;
  background: transparent;
  border: none;
  @apply gap-3 text-identityGray-200;
  font-family: 'lato';

}

.react-calendar.mini-calendar .react-calendar__viewContainer {
  height: 260px;
  width: 260px;
  @apply border-identityGray-300 border-2 p-2 text-xs rounded-xl;

}

.react-calendar.mini-calendar .react-calendar__tile {
  @apply rounded-full;
  padding: 9px 6.6667px;
}

.react-calendar.mini-calendar .react-calendar__tile--now {
  background: transparent;
  @apply border-identityGray-300 border-2;
}

.react-calendar.mini-calendar .react-calendar__navigation__prev2-button,
.react-calendar.mini-calendar .react-calendar__navigation__next2-button {
  display: none;
}

.editor-content {
  color: #fff;
  font-family: 'Lato', sans-serif;
}

.editor-content h1 {
  font-size: 30px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: 'Coolvetica', sans-serif;
}

.editor-content h2 {
  font-size: 25px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 10px;
  font-family: 'Coolvetica', sans-serif;
}

.editor-content h3 {
  font-size: 20px;
  @apply text-cyan-baby !important;
  font-family: 'Poppins', sans-serif;
}

[lang="ar"] .editor-content {
  font-family: 'Markazi', sans-serif;
  font-size: 18px;
  direction: rtl;
}

[lang="ar"] .editor-content h1 {
  font-family: 'Karagoz', sans-serif;
  font-size: 21px;
}

[lang="ar"] .editor-content h2 {
  font-family: 'Tajwal', sans-serif;
  font-size: 18px;
  font-weight: 800;
}

[lang="ar"] .editor-content h3 {
  font-family: 'Tajwal', sans-serif;
  font-size: 18px;
  font-weight: 800;
}

.reference {
  position: fixed;
  width: 100%;
  height: auto;
  left: 0;
  top: 0;
  z-index: 999999;
  opacity: 0.5;
  display: none;
}

[lang="ar"] .main-title {
  font-family: "Karagoz";
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 1px;
}

[lang="ar"] .live-text {
  font-family: "Tajwal";
  font-weight: 900;
  font-size: 8px;
  line-height: 20px;
}

[lang="ar"] .artist-name {
  font-family: "Tajwal";
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
}

[lang="ar"] .top-title {
  font-family: "Tajwal";
  font-weight: 900;
  font-size: 18px;
}
[lang="ar"] .top-title-mobile {
  font-family: "Tajwal";
  font-weight: 900;
  font-size: 16px;
}

@screen sm {

  .font-ascii {
    font-family: serif, sans-serif;
    font-size: 35px;
    margin-left: -2px;
    letter-spacing: -2px;
    margin-bottom: -2px;
    line-height: 0;
    margin-top: -3px;
  }
  [lang="ar"] .main-title {
    font-family: "Karagoz";
    font-size: 16px;
    line-height: 35px;
    letter-spacing: none;
  }
  [lang="ar"] .live-text {
    font-family: "Tajwal";
    font-weight: 900;
    font-size: 12px;
    line-height: 19px;
  }
  
  [lang="ar"] .artist-name {
    font-family: "Tajwal";
    font-weight: bold;
    font-size: 16px;
  }
  
}