.menu-item {
    @apply mb-4 text-xl text-identityGray-200 hover:text-white;
}

.menu-item.active {
    @apply text-white font-black;
}

.menu {
    @apply flex flex-col bg-identityGray-400 h-full z-50 fixed top-0 transition-[left] ease-in-out justify-between;
    max-height: calc(100vh - 186px);
    left: -72vw;
    width: 72vw;
}

.menu-inner {
    @apply flex flex-col w-full px-6 py-8 pl-10 ease-in-out gap-12;
    padding-right: 1.4rem;
    padding-top: 1.9rem;
    padding-left: 2rem;
}

.menu-back-button {
    width: 22px;
}

@screen md {
    .menu {
        @apply flex flex-col bg-identityGray-400 w-58 h-full z-50 fixed top-0 transition-[left] ease-in-out -left-58 justify-between;
        max-height: calc(100vh - 186px)
    }

    .menu-inner {
        @apply flex flex-col w-full px-6 py-6 pl-10 ease-in-out gap-12;
        padding-right: 1.6rem;
        padding-left: 2.45rem;
    }
    .menu-back-button {
        width: 26px;
    }
}

.menu-footer {
    @apply bg-identityGray-300 px-6 pl-12 py-2 text-white;
}

.logo-div {
    width: 106.5px;
}

@media (max-width: 768px) {
    .menu {
        max-height: calc(100vh - 154px);
    }
    .logo-div{
        width: 74.5px;
    }
}

.menu.active {
    @apply left-0;
}

[lang="ar"] .menu-item {
    font-family: "Tajwal";
    font-weight: bold;
    font-size: 18px;
}

[lang="ar"] .menu-item.active {
    font-weight: 900;
}