.font-emphasis {
  font-family: Verdana, sans-serif;
  font-weight: bold;
  font-style: normal;
}

.clearfix {
  *zoom: 1;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
  line-height: 0;
}

.clearfix:after {
  clear: both;
}

.hide-text {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.input-block-level {
  display: block;
  width: 100%;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.hide {
  display: none;
}

.show {
  display: block;
}

.invisible {
  visibility: hidden;
}

.affix {
  position: fixed;
}

[class^="icon-"],
[class*=" icon-"] {
  font-family: FontAwesome;
  font-weight: normal;
  font-style: normal;
  text-decoration: inherit;
  -webkit-font-smoothing: antialiased;
  *margin-right: 0.3em;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  text-decoration: inherit;
  display: inline-block;
  speak: none;
}

/* makes the font 33% larger relative to the icon container */
.icon-large:before {
  vertical-align: -10%;
  font-size: 1.3333333333333333em;
}

/* makes sure icons active on rollover in links */
a [class^="icon-"],
a [class*=" icon-"],
a [class^="icon-"]:before,
a [class*=" icon-"]:before {
  display: inline;
}

/* increased font size for icon-large */
[class^="icon-"].icon-fixed-width,
[class*=" icon-"].icon-fixed-width {
  display: inline-block;
  width: 1.2857142857142858em;
}

[class^="icon-"].icon-fixed-width.icon-large,
[class*=" icon-"].icon-fixed-width.icon-large {
  width: 1.5714285714285714em;
}

ul.icons-ul {
  list-style-type: none;
  text-indent: -0.7142857142857143em;
  margin-left: 2.142857142857143em;
}

ul.icons-ul>li .icon-li {
  width: 0.7142857142857143em;
  display: inline-block;
}

[class^="icon-"].hide,
[class*=" icon-"].hide {
  display: none;
}

.icon-muted {
  color: #eeeeee;
}

.icon-light {
  color: #ffffff;
}

.icon-dark {
  color: #333333;
}

.icon-border {
  border: solid 1px #eeeeee;
  padding: 0.2em 0.25em 0.15em;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
}

.icon-2x {
  font-size: 2em;
}

.icon-2x.icon-border {
  border-width: 2px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.icon-3x {
  font-size: 3em;
}

.icon-3x.icon-border {
  border-width: 3px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.icon-4x {
  font-size: 4em;
}

.icon-4x.icon-border {
  border-width: 4px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
}

.icon-5x {
  font-size: 5em;
}

.icon-5x.icon-border {
  border-width: 5px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  border-radius: 7px;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

[class^="icon-"].pull-left,
[class*=" icon-"].pull-left {
  margin-right: 0.3em;
}

[class^="icon-"].pull-right,
[class*=" icon-"].pull-right {
  margin-left: 0.3em;
}

/* BOOTSTRAP SPECIFIC CLASSES
   * -------------------------- */
/* Bootstrap 2.0 sprites.less reset */
[class^="icon-"],
[class*=" icon-"] {
  display: inline;
  width: auto;
  height: auto;
  line-height: normal;
  vertical-align: baseline;
  background-image: none;
  background-position: 0% 0%;
  background-repeat: repeat;
  margin-top: 0;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
     readers do not read off random characters that represent icons */
.icon-play:before {
  content: "\f04b";
}

.icon-pause:before {
  content: "\f04c";
}

.icon-stop:before {
  content: "\f04d";
}

.icon-volume-up:before {
  content: "\f028";
}

.icon-volume-off:before {
  content: "\f026";
}

.icon-calendar:before {
  content: "\f073";
}

.icon-external-link:before {
  content: "\f08e";
}

.icon-time:before {
  content: "\f017";
}

.icon-microphone:before {
  content: "\f130";
}

.icon-globe:before {
  content: "\f0ac";
}

.rj-nav {
  margin-left: 0;
  margin-bottom: 20px;
  list-style: none;
}

.rj-nav>li>a {
  display: block;
  color: #555555;
  text-decoration: none;
}

.rj-nav>li>a:hover {
  text-decoration: none;
  color: #ffffff;
}

.rj-nav>.pull-right {
  float: right;
}

.rj-nav-tabs,
.rj-nav-pills {
  *zoom: 1;
}

.rj-nav-tabs:before,
.rj-nav-pills:before,
.rj-nav-tabs:after,
.rj-nav-pills:after {
  display: table;
  content: "";
  line-height: 0;
}

.rj-nav-tabs:after,
.rj-nav-pills:after {
  clear: both;
}

.rj-nav-tabs>li,
.rj-nav-pills>li {
  float: left;
}

.rj-nav-tabs>li>a,
.rj-nav-pills>li>a {
  padding-right: 12px;
  padding-left: 12px;
  margin-right: 2px;
  line-height: 14px;
}

.rj-nav-tabs {
  border-bottom: 2px solid #ddd;
}

.rj-nav-tabs>li {
  margin-bottom: -2px;
}

.rj-nav-tabs>li>a {
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 20px;
  border: 2px solid transparent;
}

.rj-nav-tabs>li>a:hover {
  border-color: #eeeeee #eeeeee #dddddd;
}

.rj-nav-tabs>.active>a,
.rj-nav-tabs>.active>a:hover {
  color: #555555;
  background-color: #ffffff;
  border: 2px solid #ddd;
  border-bottom-color: transparent;
  cursor: default;
}

.rj-nav-stacked>li {
  float: none;
}

.rj-nav-stacked>li>a {
  margin-right: 0;
}

.rj-nav-tabs.rj-nav-stacked {
  border-bottom: 0;
}

.rj-nav-tabs.rj-nav-stacked>li>a {
  border: 2px solid #ddd;
}

.rj-nav-tabs.rj-nav-stacked>li>a:hover {
  border-color: #ddd;
  z-index: 2;
}

.tabbable {
  *zoom: 1;
}

.tabbable:before,
.tabbable:after {
  display: table;
  content: "";
  line-height: 0;
}

.tabbable:after {
  clear: both;
}

.rj-tab-content {
  overflow: auto;
}

.rj-tabs-below>.rj-nav-tabs,
.rj-tabs-right>.rj-nav-tabs,
.rj-tabs-left>.rj-nav-tabs {
  border-bottom: 0;
}

.rj-tab-content>.tab-pane,
.pill-content>.pill-pane {
  display: none;
}

.rj-tab-content>.active,
.pill-content>.active {
  display: block;
}

.rj-tabs-below>.rj-nav-tabs {
  border-top: 1px solid #ddd;
}

.rj-tabs-below>.rj-nav-tabs>li {
  margin-top: -1px;
  margin-bottom: 0;
}

.rj-tabs-below>.rj-nav-tabs>li>a {
  -webkit-border-radius: 0 0 4px 4px;
  -moz-border-radius: 0 0 4px 4px;
  border-radius: 0 0 4px 4px;
}

.rj-tabs-below>.rj-nav-tabs>li>a:hover {
  border-bottom-color: transparent;
  border-top-color: #ddd;
}

.rj-tabs-below>.rj-nav-tabs>.active>a,
.rj-tabs-below>.rj-nav-tabs>.active>a:hover {
  border-color: transparent #ddd #ddd #ddd;
}

.rj-tabs-left>.rj-nav-tabs>li,
.rj-tabs-right>.rj-nav-tabs>li {
  float: none;
}

.rj-tabs-left>.rj-nav-tabs>li>a,
.rj-tabs-right>.rj-nav-tabs>li>a {
  min-width: 74px;
  margin-right: 0;
  margin-bottom: 3px;
}

.rj-tabs-left>.rj-nav-tabs {
  float: left;
  margin-right: 19px;
  border-right: 1px solid #ddd;
}

.rj-tabs-left>.rj-nav-tabs>li>a {
  margin-right: -1px;
  -webkit-border-radius: 4px 0 0 4px;
  -moz-border-radius: 4px 0 0 4px;
  border-radius: 4px 0 0 4px;
}

.rj-tabs-left>.rj-nav-tabs>li>a:hover {
  border-color: #eeeeee #dddddd #eeeeee #eeeeee;
}

.rj-tabs-left>.rj-nav-tabs .active>a,
.rj-tabs-left>.rj-nav-tabs .active>a:hover {
  border-color: #ddd transparent #ddd #ddd;
  *border-right-color: #ffffff;
}

.rj-tabs-right>.rj-nav-tabs {
  float: right;
  margin-left: 19px;
  border-left: 1px solid #ddd;
}

.rj-tabs-right>.rj-nav-tabs>li>a {
  margin-left: -1px;
  -webkit-border-radius: 0 4px 4px 0;
  -moz-border-radius: 0 4px 4px 0;
  border-radius: 0 4px 4px 0;
}

.rj-tabs-right>.rj-nav-tabs>li>a:hover {
  border-color: #eeeeee #eeeeee #eeeeee #dddddd;
}

.rj-tabs-right>.rj-nav-tabs .active>a,
.rj-tabs-right>.rj-nav-tabs .active>a:hover {
  border-color: #ddd #ddd #ddd transparent;
  *border-left-color: #ffffff;
}

.rj-nav-tabs>.active>a,
.rj-nav-tabs>.active>a:hover {
  text-decoration: none;
}

#rj-player .rjp-trackinfo-container {
  overflow: hidden;
  width: 505px;
}

#rj-player .rjp-label {
  color: #ffffff;
  margin: 0;
  padding: 0;
  font-size: 9px;
  text-transform: uppercase;
  line-height: 1.4;
}

#rj-player .rjp-info {
  padding: 0;
  margin: 0;
}

#rj-player .rjp-player-container {
  height: 45px;
  overflow: hidden;
  position: relative;
  background: transparent;
}

#rj-player .rjp-player-container .jp-play,
#rj-player .rjp-player-container .jp-pause {
  display: block;
  font-size: 29px;
  position: absolute;
  left: 10px;
  top: 0px;
}

#rj-player .rjp-player-container .jp-play:active,
#rj-player .rjp-player-container .jp-pause:active,
#rj-player .rjp-player-container .jp-play:focus,
#rj-player .rjp-player-container .jp-pause:focus {
  text-decoration: none;
}

#rj-player .rjp-player-container .jp-play.player-loading,
#rj-player .rjp-player-container .jp-pause.player-loading {
  background-image: blue;
  background-position: center;
  background-repeat: no-repeat;
}

#rj-player .rjp-player-container .jp-mute,
#rj-player .rjp-player-container .jp-unmute {
  background: #f04e29;
  color: #ffffff;
  display: block;
  width: 30px;
  height: 30px;
  font-size: 20px;
  line-height: 1.7;
  -webkit-border-radius: 500px;
  -moz-border-radius: 500px;
  border-radius: 500px;
  position: absolute;
  left: 80px;
  bottom: 10px;
  z-index: 10;
}

#rj-player .rjp-player-container .jp-mute:active,
#rj-player .rjp-player-container .jp-unmute:active,
#rj-player .rjp-player-container .jp-mute:focus,
#rj-player .rjp-player-container .jp-unmute:focus {
  text-decoration: none;
}

#rj-player .rjp-player-container .jp-volume-bar-wrapper {
  position: absolute;
  left: 97px;
  bottom: 10px;
  -webkit-border-top-right-radius: 500px;
  -moz-border-radius-topright: 500px;
  border-top-right-radius: 500px;
  -webkit-border-bottom-right-radius: 500px;
  -moz-border-radius-bottomright: 500px;
  border-bottom-right-radius: 500px;
  z-index: 9;
}

#rj-player .rjp-player-container .jp-mute:hover~.jp-volume-bar-wrapper,
#rj-player .rjp-player-container .jp-unmute:hover~.jp-volume-bar-wrapper,
#rj-player .rjp-player-container .jp-volume-bar-wrapper:hover {
  display: block;
}

#rj-player .rjp-player-container .jp-volume-bar {
  background: transparent;
  border: 1px solid #000;
  border-radius: 3px;
  width: 65px;
  height: 8px;
  overflow: hidden;
}

#rj-player .rjp-player-container .jp-volume-bar-value {
  background: #000;
  height: 8px;
}

#rj-player .rjp-player-container .jp-no-solution {
  display: none;
}

#rj-player .rjp-player-container .rjp-stationlogo {
  position: absolute;
  right: 0;
  top: 0;
  width: 80px;
  height: 80px;
}

#rj-player a.jp-play,
#rj-player a.jp-pause,
#rj-player a.jp-mute,
#rj-player a.jp-unmute,
#rj-player a.jp-play:hover,
#rj-player a.jp-pause:hover,
#rj-player a.jp-mute:hover,
#rj-player a.jp-unmute:hover {
  text-decoration: none !important;
}

#rj-player .player-v3 {
  height: 210px;
}

#rj-player .player-v3 .rjp-label {
  color: #b74525;
}

#rj-player .player-v3 #rj-cover {
  /* float: left;
  width: 210px;
  height: 210px;
  overflow: hidden;
  background: #eae4e0;
  background-clip: content-box;
  text-align: center; */
}

#rj-player .player-v3 #rj-cover a {
  display: inline;
  line-height: 210px;
}

#rj-player .player-v3 #rj-cover a img {
  display: inline;
  width: 210px;
  height: 210px;
  vertical-align: middle;
}

#rj-player .player-v3 .info {
  position: relative;
  height: 210px;
}

#rj-player .player-v3 .rjp-player-container {
  bottom: 0;
  left: 210px;
  position: absolute;
  height: 80px;
  width: 710px;
}

#rj-player .player-v3.player-medium {
  /* width: 300px; */
  height: auto;
}

#rj-player .player-v3.player-medium .rjp-player-container {
  left: 0;
}

#rj-player .player-v3.player-medium .info {
  position: relative;
  float: left;
  width: 300px;
  height: auto;
}

#rj-player .player-v3.player-medium .rjp-player-container {
  width: 100%;
  height: 100%;
  position: absolute;
}

#rj-player .player-v3 .rjp-player-container .jp-play,
#rj-player .player-v3 .rjp-player-container .jp-pause {
  margin-right: 18px;
  right: 0;
  left: auto;
}

#rj-player .player-v3 .rjp-player-container .jp-mute,
#rj-player .player-v3 .rjp-player-container .jp-unmute {
  right: auto;
  left: 90px;
}

#rj-player .player-v3 .jp-volume-bar-wrapper {
  left: 0px;
  bottom: 8px;
  cursor: pointer;
}

.range-wrapper {
  width: var(--width);
  height: var(--height);
  position: relative;
  --width: 2px;
  --height: 60px;
  /* center the slider */
  margin: 0 auto;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 15px 0;
  /* width and height get swapped due to how the transform
    gets calculated, they will get reversed when turned vertical */
  width: var(--height);
  height: var(--width);
  left: 0;

  /* Pushes the slider slightly upward off the bottom of the line */
  bottom: -1rem;

  /* Rotation -90deg makes sliding upward increase, and
    downward decrease. TranslateY centers us since we're
    absolutely positioned */
  transform: rotate(-90deg) translateY(calc(var(--width) / 2));
  transform-origin: left;
  position: absolute;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 3.4px;
  cursor: pointer;
  background: #333333;
}

input[type=range]::-webkit-slider-thumb {
  border: 3px solid #999999;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #141414;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -8px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #333;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 3.4px;
  cursor: pointer;
  background: #333333;
  border-radius: 1.3px;
}

input[type=range]::-moz-range-thumb {
  border: 3px solid #999999;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #141414;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 3.4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  border-width: 3px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #333;
}

input[type=range]::-ms-fill-upper {
  background: #333;
}

input[type=range]::-ms-thumb {
  border: 3px solid #999999;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #141414;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #333;
}

input[type=range]:focus::-ms-fill-upper {
  background: #333;
}

.rhap_container {
  background-color: transparent;
  padding: 0;
}

.rhap_controls-section {
  display: none;
}

.rhap_time {
  @apply text-identityGray-200 text-xs;

}

.rhap_current-time:after {
  content: "/";
  @apply text-identityGray-200 px-1;
}

.rhap_progress-bar {
  height: 3px;
  direction: ltr;
}

.rhap_progress-container {
  margin: 0 calc(10px + 2%);
}

.rhap_download-progress {
  @apply bg-identityGray-300;
}

.rhap_progress-indicator {
  border: 3px solid #999999;
  height: 20px;
  width: 20px;
  border-radius: 100%;
  background: #141414;
}

.marquee span {
  min-width: 480px;
}

@media (max-width: 768px) {
  #rj-player .rjp-trackinfo-container {
    width: 100%;
  }

  .marquee span {
    min-width: 100%;
    flex-shrink: 1;
  }

  #rj-player marquee {
    line-height: 18px;
  }

  #rj-player .big-marquee {
    height: 18px !important;
    line-height: 17px;
  }

  [lang="ar"] #rj-player .big-marquee {
    padding-top: 1px;
  }

  #play-button,
  #pause-button {
    width: 2.9rem !important;
  }
}




#play-button,
#pause-button {
  width: 3.8rem;
}

#rj-player .big-marquee {
  height: 29px;
}

[lang="ar"] #rj-player .big-marquee {
  padding-top: 3px;
}